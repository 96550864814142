<template>
  <div class="Timer">
    <div class="text2">
      距离结束：<el-button
        class="setbtn"
        size="mini"
        round
        @click="setting = true"
        >Setting</el-button
      >
    </div>

    <div class="textTime">{{ hh }}:{{ mm }}:{{ ss }}</div>

    <div class="text3">{{ msg }}</div>
    <el-drawer
      size="100%"
      direction="ttb"
      :visible.sync="setting"
      :with-header="false"
    >
      <div class="setwin">
        <div>
          倒计时：
          <el-input-number
            v-model="input1"
            :min="0"
            :max="599"
            label="描述文字"
          ></el-input-number>
          分钟
        </div>
        <el-input
          class="msg"
          v-model="msg"
          placeholder="倒计时(单位:分钟)"
        ></el-input>
        <el-button type="primary" @click="start" round
          >Save and Start</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      hh: "0",
      mm: "00",
      ss: "00",
      msg: "图灵一轮笔试",
      input1: 180,
      setting: false,
      timer: "",
    };
  },
  methods: {
    start() {
      clearInterval(this.timer);
      this.setting = false;
      this.$message({
        type: "success",
        message: `OK , start !`,
      });
      var h = parseInt(this.input1 / 60);
      var m = this.input1 % 60;
      this.hh = h;
      this.mm = m < 10 ? "0" + m : m;
      this.ss = "00";
      this.timer = setInterval(this.clock, 1000);
    },
    clock() {
      if (this.ss == "00") {
        if (this.mm == "00") {
          if (this.hh == "0") {
            clearInterval(this.timer);
            this.$alert("Time’s up!", this.msg, {
            });
          } else {
            this.mm = 59;
            this.ss = 59;
            this.hh -= 1;
          }
        } else {
          this.ss = 59;
          this.mm = this.mm - 1 < 10 ? "0" + (this.mm - 1) : this.mm - 1;
        }
      } else {
        this.ss = this.ss - 1 < 10 ? "0" + (this.ss - 1) : this.ss - 1;
      }
    },
  },
};
</script>


<style scoped>
.setbtn {
  float: right;
}
.setwin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.msg {
  width: 300px;
  margin: 1vh;
}
.Timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80vw;
}
.textTime {
  font-family: Geneva, Tahoma, sans-serif;
  border: 5px rgb(199, 199, 199) solid;
  border-radius: 3vw;
  height: 27vw;
  font-size: 20vw;
  width: 80vw;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.text2 {
  width: 100%;
  font-size: 1.5vw;
  align-self: flex-start;
}
.text3 {
  font-size: 2vw;
  align-self: flex-end;
  font-weight: 100;
}
</style>
